import { useEffect, useRef } from "react";
import { useModaladdress2 } from "../../hooks/useModaladdress2";
import {
    Flex,
    Text,
    Button,
    //Image,
    Box,
    List,
    ListItem,
    ListIcon,
    useToast,
  } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { AuthService } from "../../services/AuthService";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { AddressModel } from "../../models/AddressModel";
import { prepareModal } from "../../helper/Render";
import { useCityAddress } from "../../hooks/useCityAddress";


export const AskForAddress2 = () => {
    const mapRef = useRef<HTMLDivElement>(null);
    const auntoCompleteRef = useRef<HTMLInputElement>(null);
    const widget = useWidget();
    const addr = useAddress();
    const toast = useToast();
    const cityAddress = useCityAddress();
   
     
    if (widget == null) return <></>;

    prepareModal(widget.isOpenModalAskForAddress);

    const {
        buttonSaveActive,
        //styleInputMap,
        country,
        locality,
        formatAddress,
        latitude,
        longitude,
        iniMap,
      } = useModaladdress2();

      const styleInputMap = {
        border: "2px solid rgb(166, 165, 166)",
        borderRadius: "50px",
        width: "85%",
        height: "45px",
        margin: "10px auto",
        paddingLeft: "15px",
        background: "rgb(255, 252, 255)",
    }

      useEffect(() => {
        iniMap(mapRef.current, auntoCompleteRef.current);
      }, []);

      const addCityAddress = async(): Promise<boolean> => {
        const message = await cityAddress.addCityAddress();
        if (message.code === 400) {
          toast({
            title: message.title,
            description: message.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return false;
        }
        return true;
      }

      const handlerSaveAddress = async (): Promise<void> => {
            if (formatAddress == "") {
                toast({
                  title: "Disculpe.",
                  description: "Complete los datos de la dirección para continuar.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return;
            }
            const newAddress: AddressModel = {
              id: 0,
              customer_id: "",
              country_name: country,
              city_name: locality,
              address: formatAddress,
              additional_info: "",
              latitud: latitude,
              longitud: longitude,
              place_Id: "--",
              commission: 0,
            };
            if (!AuthService.validateSession()) {
              // INFO: Se coloca esto por que ahora que no esta la ventana que creaba el usuario con la ciudad. 
              //       Si no esta se tiene que crear primero para evitar que el modal falle la primera vez cuando aun no existe el usuario.
              const response = await addCityAddress();
              if (!response) return;
            }
            const result = await addr?.changeAddress(newAddress);
            if (result?.code === 200) {
              widget.openCloseModalAskForAddress(false);
              widget?.handlerSteps("product");
              widget?.openWidget();
            }
            toast({
              title: result?.title,
              description: result?.message,
              status: result?.type,
              duration: 9000,
              isClosable: true,
            });
        };

      const handleOverlayClick = (e: React.MouseEvent) => {
        // Verifica si el clic ocurrió en el overlay y no dentro del modal
        if (e.target === e.currentTarget) {
          widget?.openCloseModalAskForAddress(false); // Llama a la función para cerrar el modal
        }
      };
      
      
    return (
      <Box
        flexDir="column"
        alignItems="center"
        justifyContent={"center"}
        position={"fixed"}
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="100%"
        h="100%"
        bg={"rgba(0, 0, 0, 0.5)"}
        zIndex="1000"
        display={!widget?.isOpenModalAskForAddress ? "none" : "flex"}
        onClick={handleOverlayClick}
      >
        <Flex
          id="trycloud-external-modal-address"
          direction="column"
          alignItems="center"
          bg="white"
          border={"1px solid #ddd"}
          shadow={"none"}
          overflowY="auto"
          borderRadius={"5px"}
          w={"90%"}
          maxW={"400px"}
          //p="1rem"
          gap="1.6rem"
          display={!widget?.isOpenModalAskForAddress ? "none" : "flex"}
        >
          <Flex direction="column" justifyContent="space-between" w="100%">
            <Text fontWeight="bold" fontSize="24px" textAlign="center" pt={12} color={"#555"}>
              Pruébatelo antes de comprar
            </Text>
            {/* <Text fontSize="20px" textAlign="left" color="#555" mt={4} mb={1} ml={"35px"}>
              Ingresa tu ubicación
            </Text> */}
          </Flex>
          <input
            type="text"
            id="place_input"
            ref={auntoCompleteRef}
            placeholder="Ingresa tu dirección"
            style={styleInputMap}
          />
          <div 
            id="map" 
            ref={mapRef} 
            style={
              { width:"260px", 
                minHeight: "115px",
                display: formatAddress === "" ? "none" : "block",
                marginBottom: "10px",
                //border: "1px solid #555", // Agregar borde para que se vea el contorno del mapa
              }}
            >
            </div>
             <List spacing={2} mb={6} px={9} w="full">
                <ListItem display="flex" alignItems="center">
                  <ListIcon as={CheckIcon} color="green.500" />
                  <Text fontSize={"15px"} textColor={"#555"}>
                    Recibe hoy
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                  <ListIcon as={CheckIcon} color="green.500" />
                  <Text fontSize={"15px"} textColor={"#555"}>
                    Paga solo si te queda
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                  <ListIcon as={CheckIcon} color="green.500" />
                  <Text fontSize={"15px"} textColor={"#555"}>
                    Nos llevamos el resto en 24hrs
                  </Text>
                </ListItem>
              </List>
          <Button
            onClick={handlerSaveAddress}
            w= {"85%"}
            bg="#242424"
            color="white"
            h={"56px"}
            fontSize={"18px"}
            mb={"24px"}
            isDisabled={!buttonSaveActive}
            //isLoading={addr?.isLoading}
            loadingText="Espere un momento"
            _hover={{ bg: "black.400" }}
          >
            Continuar
          </Button>
        </Flex>
      </Box>
    );
}
