import { useState } from "react";
import { WidgetProvider } from "./providers/WidgetProvider";
import Widget from "./Widget";
import { DataConfigModel } from "./models/DataConfigModel";
import { AuthService } from "./services/AuthService";
import TopBanner from "./components/modal/externalmodal/TopBanner";
import TryCloudModal from "./components/modal/externalmodal/TryCloudModal";
import { HowToWorkExternal } from "./components/customer/HowToWorkExternal";
import { Box } from "@chakra-ui/react";
import { existParameterTrycloud } from "./helper/Parameters";

interface TopBannerProps {
    configResponse: DataConfigModel | null;
    shadowHostRef: React.RefObject<HTMLDivElement>;
    isProductDetail: boolean;
}

export const MainApp = ({ configResponse, shadowHostRef, isProductDetail }: TopBannerProps) => {
    const [isTrycloudActive, setIsTrycloudActive] = useState<boolean>(() =>{
        return localStorage.getItem("tryCloud") === "true" && AuthService.validateSession()
    });
    const [customerReceptionPage, setCustomerReceptionPage] = useState<"modal-main" | "modal-howToWork" | undefined>(() => {
        if (isProductDetail) return undefined;
        if (!existParameterTrycloud()) return undefined;
        // SI Existe el parametro Trycloud en la url y no esta logeado, mostramos el modal-main.
        if (!AuthService.validateSession()){
           return "modal-main"; 
        } else {
            // si existe el parametro Trycloud en la url y esta logeado, mostramos el modal-howToWork.
            return "modal-howToWork";
        }

    });

    //console.log("Trycloud: customerReceptionPage: ", customerReceptionPage);

    const changeAddressToExternalModal = () => {
        setCustomerReceptionPage("modal-howToWork");
        localStorage.setItem("tryCloud" , "true");
        setIsTrycloudActive(true);
    };

 
    if (isProductDetail) {
        return (
            <WidgetProvider configData={configResponse} shadowHostRef={shadowHostRef}>
                <Widget />
            </WidgetProvider>
        );
    }

    let content;

    switch (customerReceptionPage) {
        case "modal-main":
        content = <TryCloudModal  isOpenAddressExternal={true} changeAddressToExternalModal={changeAddressToExternalModal} />;
        break;
        case "modal-howToWork":
        content = <HowToWorkExternal isActive={isTrycloudActive} setCustomerReceptionPage={setCustomerReceptionPage} setIsTrycloudActive={setIsTrycloudActive} />;
        break;
    }

    return (
        <Box id="trycloud-external" w={"100%"}>
            <TopBanner isOpenBannerTop={isTrycloudActive} setCustomerReceptionPage={setCustomerReceptionPage} />
            {content}
        </Box>
    );
};
